@import '~@douyinfe/semi-ui/dist/css/semi.min.css';

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-y: scroll;
  overscroll-behavior-y: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.extra-large-button {
  height: 48px;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 22px;
}

.semi-cascader-option-lists {
  max-width: 95vw;
  overflow-x: auto;
}

.ebook-menuitem {
  line-height: 30px;
}

.quick-indexing-input-leftmost {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.quick-indexing-input-rightmost {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}