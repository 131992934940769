.circle{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
}

.circle div{
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	border-radius: 70%;
	animation: animate 25s linear infinite;
	bottom: -150px;
}

.circle div.blue{
	background: rgba(35, 104, 197, 0.3);
}

.circle div.red{
	background: rgba(249, 82, 81, 0.8);
}

.circle div.green{
	background: rgba(79, 189, 134, 0.8);
}

.circle div.yellow{
	background: rgba(251, 192, 45, 0.9);
}

.circle div:nth-child(1){
	left: 25%;
	width: 80px;
	height: 80px;
}

.circle div:nth-child(2){
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 2s;
	animation-duration: 12s;
}

.circle div:nth-child(3){
	left: 20%;
	width: 20px;
	height: 20px;
	animation-delay: 4s;
	animation-duration: 12s;
}

.circle div:nth-child(4){
	left: 50%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 18s;
}

.circle div:nth-child(5){
	left: 35%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 10s;
}

.circle div:nth-child(6){
	left: 35%;
	width: 80px;
	height: 80px;
	animation-delay: 3s;
	animation-duration: 12s;
}

.circle div:nth-child(7){
	left: 25%;
	width: 110px;
	height: 110px;
	animation-delay: 3s;
	animation-duration: 10s;
}

.circle div:nth-child(8){
	left: 20%;
	width: 50px;
	height: 50px;
	animation-delay: 2s;
	animation-duration: 12s;
}

@keyframes animate{
	0%{
		transform: translateY(0) rotate(0deg);
	}

	100%{
		transform: translateY(-1000px) rotate(720deg);
	}
}

.quick-indexing {
	margin-top: -40px;
}

.quick-indexing-slidein {
	margin-top: 0px;
}